import React, { useRef } from "react";
import { Link } from "gatsby";

import { handleBeforeunLoad } from "../../../utils/mixpanelEvents/mixpanelSignupFormEvent";
import usePageSignupPageStore from "../../../sp-state/usePageSignupPageStore";

import styles from "./MonarchSignUpHeader.module.scss";
import { trackMxpEvent } from "@utils/mixpanelEvents/mixpanelEvents";

const MonarchSignUpHeader: React.FC = () => {
  const { timeStart, timeEnd, resetPageState } = usePageSignupPageStore(
    state => state
  );
  const pageState = useRef({ timeEnd, timeStart });

  const handleLogoClick = () => {
    trackMxpEvent("user: Logo clicked");
    handleBeforeunLoad(pageState.current);
    resetPageState();
  };

  const logo = (
    <figure className={styles.monarchSignUpHeaderLogo}>
      <img
        className={styles.imgFallBack}
        src="/images/monarch-sp.svg"
        alt="logo"
      />
    </figure>
  );

  return (
    <header className={styles.monarchSignUpHeader}>
      <nav className={`${styles.monarchSignUpHeaderNav} full-width`}>
        <Link
          to="/"
          onClick={handleLogoClick}
          className={styles.monarchSignUpHeaderLogoWrapper}
        >
          {logo}
        </Link>

        <p>
          <span className="hide-for-small">Have an account?</span>{" "}
          <Link data-testid="a:MonarchTrialSignUpHeader" to="/sign-in">
            Sign In
          </Link>
        </p>
      </nav>
    </header>
  );
};

export default MonarchSignUpHeader;
